<template>
  <div>
    <div class="backdrop"></div>
    <div class="container col-lg-4 p-5">
      <form class="row align-items-center p-5 border bg-light foredrop" @submit.prevent="login">
        <div class="mb-3">
          <label for="username" class="form-label">Gebruikersnaam</label>
          <input type="text" v-model="username" class="form-control" id="username" aria-describedby="emailHelp">
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Wachtwoord</label>
          <input type="password" v-model="password" class="form-control" id="password">
        </div>
        <div class="mb-3 form-check">
          <input type="checkbox" v-model="rememberme" class="form-check-input" id="rememberme">
          <label class="form-check-label" for="rememberme">Onthoud mij</label>
        </div>
        <button type="submit" class="btn btn-primary">Aanmelden</button>
        <p class="alert alert-danger mt-3" v-if="loginerror">
          Uw gegevens zijn niet gevonden, probeer het opnieuw.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { state } from "../state"

export default {
  name: 'Login',
  data() {
    return {
      state: state,
      username: '',
      password: '',
      rememberme: false,
      loginerror: false
    }
  },
  methods: {
    login() {
      var me = this;

      me.loginerror = false;

      var data = { username: me.username, password: me.password, rememberme: me.rememberme };
      fetch("/api/auth/authenticate", {
        method: "post",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(data)
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            // successful
            me.state.validateLogin(data.object);
          }
          else {
            me.loginerror = true;
          }
        });
    }
  },
  mounted() {
    this.state.showbackdrop = true;
  },
  beforeUnmount() {
    this.state.showbackdrop = false;
  }
}
</script>

