import * as VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home"
  }
];

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})

export default router;
