<template>
  <div class="dropdown">
    <button class="btn dropdown-toggle" type="button" href="#" role="button" id="dropdownMenuLink"
      data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-person-circle"></i>
    </button>

    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
      <li><span class="dropdown-item">{{ state.user.name }}</span></li>
      <li><a class="dropdown-item" href="#" @click.stop="logoff">Afmelden</a></li>
    </ul>
  </div>
</template>

<script>
import { state } from "../state"

export default {
  name: 'AccountInfo',
  data() {
    return {
      state: state
    }
  },
  methods: {
    logoff() {
      fetch("/api/auth/logoff", { method: "post" })
        .then(response => this.state.invalidateLogin());
    }
  }
}
</script>

