<template>

  <div>
    <div id="imagesholder" v-if="state.login" v-on:scroll.passive="onScroll($event)">
      <div v-for="(img, index) in state.images" v-bind:key="img.id" @click.stop="showImage(index)">
        <ProgressiveImage object-cover v-bind:src="img.url" blur="0" />
        <span class="date">{{ img.date }}</span>
        <span class="cam">{{ img.cam }}</span>
      </div>
    </div>

    <div id="popupimg" v-if="popupimg" @click.stop="popupimg = null">
      <img v-bind:src="'/api/images/image?org=' + popupimg.org + '&id=' + popupimg.id" />
      <div id="imgbtns"><a href="#" class="navbw" @click.stop="prevImage()" v-bind:class="imgindex == 0 ? 'dim' : ''">
          <i class="bi bi-arrow-left-circle"></i></a>
        <a target="_blank" v-bind:href="'/api/images/image?org=' + popupimg.org + '&id=' + popupimg.id" class="navdl">
          <i class="bi bi-arrow-down-circle"></i></a>
        <a href="#" class="navfw" @click.stop="nextImage()"
          v-bind:class="imgindex == state.images.length - 1 ? 'dim' : ''">
          <i class="bi bi-arrow-right-circle"></i></a>
      </div>
    </div>
  </div>

</template>

<script>
import { state } from "../state"

export default {
  name: 'NavMenu',
  data() {
    return {
      state: state,
      imgindex: 0,
      popupimg: null

    }
  },
  methods: {
    onScroll(event) {
      var target = event.target;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        this.state.loadImages(true);
      }
    },
    showImage(index) {
      this.imgindex = index;
      var img = this.state.images[this.imgindex];
      this.popupimg = img;
    },
    nextImage() {
      if (this.imgindex == this.state.images.length - 1)
        return;

      this.showImage(this.imgindex + 1);
    },
    prevImage() {
      if (this.imgindex == 0)
        return;

      this.showImage(this.imgindex - 1);
    }
  }
}
</script>

