<template>
  <div class="container-fluid h-100 px-0" v-cloak>
    <!-- HEADER WITH NAV -->

    <nav class="navbar navbar-expand-lg navbar-light shadow-sm">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src="./assets/logo.png" />Wilsus portal</a>
        <!-- LOGINSTATUS -->
        <div v-if="!state.loginrequired">
          <AccountInfo></AccountInfo>
        </div>
      </div>
    </nav>

    <div id="toolbar" class="bg-light p-1 border-bottom" v-if="state.login">
      <!-- MENU TOGGLE -->
      <button id="refreshbtn" class="btn btn-primary btn-sm m-1" @click.stop="state.loadImages()">
        <i class="bi bi-arrow-clockwise"></i> <span>Verversen</span>
      </button>
      <button id="menutogglebtn" type="button" class="btn btn-light border btn-sm m-1" @click.stop="state.toggleMenu()">
        <i class="bi" v-bind:class="state.menu ? 'bi-arrow-left-circle' : 'bi-filter-circle'"></i> <span>Filter</span>
      </button>

      <!-- sorting -->
      <!-- <div class="btn-group">
        <button class="btn btn-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          Sorting: {{ state.sort == 'desc' ? 'nieuwe eerst' : 'oude eerst' }}
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#" @click.stop="state.sort('desc')">Nieuwe eerst</a></li>
          <li><a class="dropdown-item" href="#" @click.stop="state.sort('asc')">Oude eerst</a></li>
        </ul>
      </div> -->
    </div>

    <div class="" v-bind:class="state.menu ? 'appmenu' : ''">
      <!-- MENU -->
      <div class="menubar col-lg-2 px-2">
        <NavMenu v-if="state.login"></NavMenu>
      </div>
      <!-- IMAGES -->
      <Images></Images>
      <!-- LOGIN -->
      <Login v-if="state.loginrequired"></Login>
    </div>

  </div>
</template>

<script>
import AccountInfo from './components/AccountInfo.vue'
import NavMenu from './components/NavMenu.vue'
import Images from './components/Images.vue'
import { state } from './state'
import Login from './components/Login.vue'

export default {
  name: 'App',
  components: {
    AccountInfo,
    NavMenu,
    Images,
    Login
  },
  data() {
    return {
      state: state
    }
  },
  mounted() {
    this.state.refreshAuthState();
  }

}
</script>

<style>
</style>
