import { reactive } from 'vue'

export const state = reactive({
    login: false,
    user: {},
    menu: false,
    images: [],
    pageSize: 100,
    pageToken: '',
    sortOrder: 'desc',
    morePages: true,
    imagesfilter: {
        org: '',
        allcams: true,
        cams: [],
        datefrom: '',
        dateto: ''
    },
    loading: false,
    loginrequired: false,
    showbackdrop: false,
    invalidateLogin() {
        this.user = {};
        this.login = false;
        this.loginrequired = true;
    },
    validateLogin(username) {
        this.user = { name: username };
        this.loginrequired = false;
        this.refreshAuthState();
    },
    refreshAuthState() {
        var me = this;
        fetch("/api/auth/refresh")
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    me.login = true;
                    me.user = data.object;
                    me.imagesfilter.org = data.object.organisations[0].name;
                    console.log(me.imagesfilter.org);
                    me.loadImages();
                }
                else {
                    me.login = false;
                    me.user = {};
                    me.loginrequired = true;
                }
            });
    },
    toggleMenu() {
        this.menu = !this.menu;
    },
    sort(sortOrder) {
        this.sortOrder = sortOrder;
        this.loadImages();
    },
    loadImages(infinite = false) {
        var me = this;

        if (!infinite) { // reload page 1
            me.images = [];
            me.pageToken = '';
            me.morePages = true;
        }
        else if (!me.morePages)
            return;

        me.menu = false; // hide menu

        var url = "/api/images/images?org=" + me.imagesfilter.org
            + "&pageSize=" + me.pageSize
            + "&pageToken=" + me.pageToken
            + "&sort=" + me.sortOrder
            + "&datefrom=" + me.imagesfilter.datefrom
            + "&dateto=" + me.imagesfilter.dateto;

        console.log(url);
        
        if (!me.imagesfilter.allcams)
            url += "&cams=" + me.imagesfilter.cams.join();

        fetch(url)
            .then((response) => {
                if (!response) return;
                if (response.ok) {
                    return response.json();
                }
                if (response.status == 403) {
                    me.images = [];
                    me.invalidateLogin();
                    return;
                }
            })
            .then((data) => {
                if (!data) return;
                console.log(data.pageToken);
                me.pageToken = data.pageToken;
                me.images.push(...data.items);
                if (me.pageToken == null)
                    me.morePages = false;
            })
            .catch((error) => {
                console.log(error)
            });
    }
})