import * as Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './assets/default.css'
import "vue-progressive-image/dist/style.css"; // <--- very important!
import VueProgressiveImage from 'vue-progressive-image'

const app = Vue.createApp(App);

app.use(router);

app.use(VueProgressiveImage);

app.mount('#app');
