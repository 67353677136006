<template>

  <div class="pb-4">

    <h5 class="mt-2"><i class="bi bi-calendar"></i> Periode</h5>
    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text" id="basic-addon1">Van</span>
      <input v-model="state.imagesfilter.datefrom" type="date" class="form-control" id="datestart"
        aria-describedby="basic-addon1" />
    </div>

    <div class="input-group input-group-sm mb-3">
      <span class="input-group-text" id="basic-addon1">T/m</span>
      <input v-model="state.imagesfilter.dateto" type="date" class="form-control" id="dateend"
        aria-describedby="basic-addon1" />
    </div>

    <h5 class="border-top pt-2 mt-4"><i class="bi bi-camera"></i> Camera</h5>

    <div v-for="org in state.user.organisations" v-bind:key="org">
      <!-- <h6 class="mt-4">{{ org.name }}</h6> -->
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
          v-model="state.imagesfilter.allcams" value="true" @change="changecamall">
        <label class="form-check-label" for="flexRadioDefault1">
          Alles
        </label>
      </div>
      <div v-bind:class="state.imagesfilter.allcams ? 'dim' : ''">
        <div class="form-check form-switch" v-for="cam in org.cameras" v-bind:key="cam">
          <input class="form-check-input" v-model="state.imagesfilter.cams" type="checkbox" v-bind:id="'cam' + cam"
            @change="changecam" v-bind:value="cam.replace(' ', '_')">
          <label class="form-check-label" v-bind:for="'cam' + cam">{{ cam }}</label>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { state } from "../state"

export default {
  name: 'NavMenu',
  data() {
    return {
      state: state
    }
  },
  methods: {
    changecam() {
      this.state.imagesfilter.allcams = !this.state.imagesfilter.cams.length;
    },
    changecamall() {
      if (this.state.imagesfilter.allcams) {
        this.state.imagesfilter.cams = [];
      }
    }
  },
  mounted() {
  }
}
</script>

